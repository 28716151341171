<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <h1 class="font-weight-light mb-0">System Settings</h1>
      <span class="overline">Manage my Settings</span>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col lg="3">
                <v-card class="mb-4 pa-5">
                  <v-list class="" dense nav>
                    <v-list-item
                      v-for="item in items"
                      :key="item.title"
                      link
                      :to="item.href"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col lg="9">
                <v-container>
                  <router-view> </router-view>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  template: "#settings",
  data() {
    return {
      items: [
        {
          title: "General",
          icon: "mdi-cogs",
          href: "generalSettings",
        },
        {
          title: "Profile",
          icon: "mdi-account-circle-outline",
          href: "profileSettings",
        },
        {
          title: "Company",
          icon: "mdi-warehouse",
          href: "companySettings",
        },
        {
          title: "My Users",
          icon: "mdi-account-multiple",
          href: "myUsers",
        },
      ],
    };
  },
};
</script>
